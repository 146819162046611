import { Box, Button } from "@chakra-ui/react";
import { DealPartyRelation, Party, PartyType, StatusCode } from "@elphi/types";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import elphiTheme from "../../assets/themes/elphi.theme.default";
import { AppConfig } from "../../config/appConfig";
import { useSections } from "../../forms/schemas/providers/SectionsProvider";
import { useDealPartyRelationHooks } from "../../hooks/dealpartyrelation.hooks";
import { usePartyHooks } from "../../hooks/party.hooks";
import { usePartyRelationHooks } from "../../hooks/partyrelation.hooks";
import { useSnapshotHooks } from "../../hooks/snapshot.hooks";
import { RootState } from "../../redux/store";
import { NavigationPath } from "../../shared/types/navigation.types";
import AccordionSections from "../ScrollableSections";
import FormBuilder, { SectionHeader } from "../form-builder/FormBuilder";
import { useFormBuilderStateHandler } from "../form-builder/InputBuilder";
import { useElphiToast } from "../toast/toast.hook";

type DealPartyRelationFormProps = {
  dealId: string;
  expandDealPartyRelationshipSection?: boolean;
  navigationPath?: NavigationPath;
};
type DealPartyRelationStructureState = {
  relations: { [id: string]: DealPartyRelation };
};

export const DealPartyRelationFormContainer = (
  props: DealPartyRelationFormProps & { snapshotId?: string }
) => {
  return props.snapshotId ? (
    <DealPartyRelationFormSnapshotContainer
      {...props}
      snapshotId={props.snapshotId!}
    />
  ) : (
    <DealPartyRelationFormLiveStateContainer {...props} />
  );
};
const DealPartyRelationFormSnapshotContainer = (
  props: DealPartyRelationFormProps & { snapshotId: string } & {
    navigationPath?: NavigationPath;
  }
) => {
  const { snapshotDataState } = useSnapshotHooks();
  const {} = usePartyRelationHooks();
  const { partyTab } = usePartyHooks();
  const snapshot = useMemo(
    () => snapshotDataState({ snapshotId: props.snapshotId }),
    [props.snapshotId]
  );

  const highlightedParty = useMemo(
    () =>
      partyTab &&
      partyTab?.highlightedPartyId &&
      snapshot.partyState?.entities?.[partyTab.highlightedPartyId],
    [partyTab?.highlightedPartyId, snapshot.partyState]
  );
  const selectedDealPartyRelationState = useMemo(() => {
    if (!props.dealId || !partyTab?.highlightedPartyId) {
      return undefined;
    }
    return snapshot?.dealPartyRelationState?.entities[
      `${props.dealId}_${partyTab?.highlightedPartyId}`
    ];
  }, [props.dealId, partyTab?.highlightedPartyId]);
  return (
    <DealPartyRelationForm
      dealId={props.dealId}
      createDealParty={(_: any): any => {}}
      isLoading={false}
      snapshotId={props.snapshotId}
      selectedDealPartyRelationState={selectedDealPartyRelationState}
      selectedParty={highlightedParty || undefined}
      updateDealPartyRelationBatch={(_: any): any => {}}
    />
  );
};
 const DealPartyRelationFormLiveStateContainer = (
  props: DealPartyRelationFormProps
) => {
  const {
    updateBatch: updateDealPartyRelationBatch,
    createDealParty,
    createDealPartyApiResponse
  } = useDealPartyRelationHooks();
  const selectedDealPartyRelationState = useSelector((state: RootState) => {
    return state.dealPartyRelation.entities[
      `${props.dealId}_${state.party.partyTab?.highlightedPartyId}`
    ];
  });
  const selectedParty = useSelector((state: RootState) => {
    return (
      state.party.partyTab?.highlightedPartyId &&
      state.party.entities[state.party.partyTab?.highlightedPartyId]
    );
  });
  return (
    <DealPartyRelationForm
      {...props}
      updateDealPartyRelationBatch={updateDealPartyRelationBatch}
      createDealParty={createDealParty}
      isLoading={createDealPartyApiResponse.isLoading}
      selectedDealPartyRelationState={selectedDealPartyRelationState}
      selectedParty={selectedParty || undefined}
      expandDealPartyRelationshipSection={
        props.expandDealPartyRelationshipSection
      }
    />
  );
};
export const DealPartyRelationForm = (
  props: DealPartyRelationFormProps & {
    updateDealPartyRelationBatch: ReturnType<
      typeof useDealPartyRelationHooks
    >["updateBatch"];
    createDealParty: ReturnType<
      typeof useDealPartyRelationHooks
    >["createDealParty"];
    isLoading: boolean;
    snapshotId?: string;
    selectedDealPartyRelationState?: DealPartyRelation;
    selectedParty?: Partial<Party>;
    expandDealPartyRelationshipSection?: boolean;
    navigationPath?: NavigationPath;
    elphiView?: "accordion" | "form";
    inputSize?: {
      minW: string;
    };
  }
) => {
  const { errorToast, successToast } = useElphiToast();
  const [isExpanded, setIsExpanded] = useState(false);
  const { sections } = useSections();

  useEffect(() => {
    setIsExpanded(props?.expandDealPartyRelationshipSection ?? false);
  }, [props?.expandDealPartyRelationshipSection]);

  const {
    updateDealPartyRelationBatch,
    createDealParty,
    selectedDealPartyRelationState,
    selectedParty,
    elphiView,
    inputSize
  } = props;

  const updateDealPartyRelationHandler = async (
    diff: Partial<DealPartyRelationStructureState>
  ) => {
    if (!diff.relations) return null;
    const relations = Object.keys(diff.relations)
      .map((id) => {
        if (diff.relations) {
          return {
            ...diff.relations[id],
            id
          };
        }
      })
      .filter((v) => v !== undefined);
    if (!relations.length) return null;

    return await updateDealPartyRelationBatch({ relations } as {
      relations: ({
        id: string;
      } & Partial<DealPartyRelation>)[];
    }).then((r) => {
      if (r.status === 200) {
        successToast({
          title: "Deal-Party Relations Updated",
          description: `${r.data.batch.length} relations updated`
        });
        setRelationDiffState({ relations: {} });
      }
      if (r.status === 400) {
        errorToast({
          title: "Failed to update deal-party relations",
          description: r.data.description
        });
      }
      return r;
    });
  };
  const {
    onChange: relationOnChange,
    state: relationState,
    setDiffState: setRelationDiffState,
    syncState: syncRelationState
  } = useFormBuilderStateHandler<DealPartyRelationStructureState>({
    initialState: { relations: {} } as DealPartyRelationStructureState,
    callback: updateDealPartyRelationHandler,
    callbackOptions: {
      debounceRate: AppConfig.debounceRate,
      clearDiff: true
    }
  });
  useEffect(() => {
    if (selectedDealPartyRelationState) {
      syncRelationState({
        shouldSync: !!selectedDealPartyRelationState,
        state: selectedDealPartyRelationState,
        statePath: () => {
          if (selectedDealPartyRelationState) {
            return ["relations", selectedDealPartyRelationState!.id!];
          }
        }
      });
    }
  }, [selectedDealPartyRelationState]);

  const fullDealPartyRelationOnChange = (v) => {
    selectedDealPartyRelationState &&
      relationOnChange({
        fieldType: v.fieldType,
        fieldKey: [
          "relations",
          selectedDealPartyRelationState.id,
          ...v.fieldKey
        ],
        value: v.value
      });
  };
  const currentDealPartyRelation = selectedDealPartyRelationState?.id
    ? relationState.relations[selectedDealPartyRelationState.id]
    : ({} as DealPartyRelation);

  const defaultIndex: string = useMemo(() => {
    return isExpanded ? "0" : "";
  }, [isExpanded]);

  const onCreateDealParty = () => {
    selectedParty?.id &&
      createDealParty(props.dealId, selectedParty.id).then((r) => {
        if (r.status === StatusCode.OK) {
          successToast({
            title: "Deal-Party Relations Created",
            description: `relation:${r?.data?.relation?.id}`
          });
          setIsExpanded(true);
        }
        if (r.status === StatusCode.BadRequest) {
          errorToast({
            title: "Failed to create deal-party relations",
            description: r?.data?.description
          });
        }
      });
  };

  const relationSection = useMemo(() => {
    const dealPartyRelationSection =
      sections?.dealPartyRelation?.dealPartyRelationSection?.({
        state: currentDealPartyRelation,
        onChange: !props.snapshotId ? fullDealPartyRelationOnChange : (_) => {},
        options: { elphiView }
      });

    return (
      selectedParty && (
        <Box>
          {selectedDealPartyRelationState ? (
            <FormBuilder
              customKey="dealPartyRelationSection"
              elphiView={elphiView}
              isDisabled={!selectedDealPartyRelationState || !!props.snapshotId}
              onChange={
                !props.snapshotId ? fullDealPartyRelationOnChange : (_) => {}
              }
              sections={[dealPartyRelationSection]}
              defaultIndex={defaultIndex
                ?.split(",")
                .map((n: string) => Number(n))}
              navigationPath={props.navigationPath}
              size={inputSize}
            />
          ) : (
            <AccordionSections
              customKey="dealPartyRelationship"
              defaultIndex={defaultIndex
                ?.split(",")
                .map((n: string) => Number(n))}
              sections={[
                {
                  header: <SectionHeader header={"Deal-Party Relationship"} />,
                  body: (
                    <Box p="10px">
                      <Button
                        {...elphiTheme.components.light.button.primary}
                        isLoading={props.isLoading}
                        isDisabled={!!props.snapshotId}
                        onClick={onCreateDealParty}
                      >
                        {`${
                          selectedParty.PartyType === PartyType.Individual
                            ? "Add Individual Roles"
                            : "Add Party As Borrower"
                        }`}
                      </Button>
                    </Box>
                  )
                }
              ]}
              navigationPath={props.navigationPath}
            />
          )}
        </Box>
      )
    );
  }, [
    selectedParty,
    relationState,
    selectedDealPartyRelationState,
    props.isLoading,
    defaultIndex
  ]);

  return <Box>{relationSection}</Box>;
};
