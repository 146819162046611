import { CreditScore } from "@elphi/types/entities/creditReport.types";
import {
  createSlice,
  EntityId,
  EntityState,
  PayloadAction
} from "@reduxjs/toolkit";
import { keyBy, union } from "lodash";
import { removeNulls } from "../../../utils/filter.utils";
import sliceBuilder from "../builders/slice.builder";
import { partyApi } from "../party";
import { creditReportEntityAdapter as entityAdapter } from "./creditReport.adapter";
import { creditReportApi } from "./creditReport.service";

export type CreditReportSliceState = EntityState<CreditScore> & {
  selectedId?: EntityId;
};

export const creditReportSlice = createSlice({
  name: "creditReport",
  initialState: entityAdapter.getInitialState({
    selectedId: undefined
  }) as CreditReportSliceState,
  reducers: {
    update: entityAdapter.updateOne,
    remove: entityAdapter.removeOne,
    add: entityAdapter.addOne,
    upsert: entityAdapter.upsertOne,
    upsertMany: entityAdapter.upsertMany,
    removeMany: entityAdapter.removeMany,
    updateMany: entityAdapter.updateMany,
    selectedId: (state, action: PayloadAction<{ id: EntityId }>) => {
      state.selectedId = action.payload.id;
    }
  },
  extraReducers: (builder) => {
    sliceBuilder.crudExtraReducers(creditReportApi)(builder);
    builder.addMatcher(
      creditReportApi.endpoints.getLatestCreditScore.matchFulfilled,
      (state, { payload }) => {
        state.ids = union(state.ids, [payload.id]);
        state.entities = { ...state.entities, ...{ [payload.id]: payload } };
      }
    );
    builder.addMatcher(
      partyApi.endpoints.getPartyCreditScores.matchFulfilled,
      (state, { payload }) => {
        state.ids = union(
          state.ids,
          payload.map((p) => p.id)
        );
        state.entities = { ...state.entities, ...keyBy(payload, "id") };
      }
    );
    builder.addMatcher(
      creditReportApi.endpoints.getBatchPartyCreditScores.matchFulfilled,
      (state, { payload }) => {
        state.ids = union(
          state.ids,
          payload.creditScores.map((cs) => cs.id).filter(removeNulls)
        );
        state.entities = {
          ...state.entities,
          ...keyBy(payload.creditScores || [], "id")
        };
      }
    );
  }
});
