import { Box, Text, Tooltip } from "@chakra-ui/react";
import {
  Asset,
  AssetType,
  Deal,
  DealPartyRelation,
  EntityParty,
  IndividualSSNParty,
  IndividualTINParty,
  LoanPartyEntityRoleType,
  LoanPartyIndividualRoleType,
  Party,
  PartyType,
  Property,
  Statement
} from "@elphi/types";
import { WritableDraft } from "immer/dist/internal";
import { intersection } from "lodash";
import { NOT_AVAILABLE } from "../../../constants/common";
import { assetToString, statementToString } from "../../asset/utils/printUtils";
import {
  getDealIdentifier,
  getPartyName,
  getPropertyAddress
} from "../../task/taskPrint.utils";

export const splitRelationId = (s: string) => {
  const idArray = s.split("_");
  const prefixId = idArray[0];
  const suffixId = idArray[1];
  return {
    prefixId,
    suffixId
  };
};

type CurrentParty =
  | Partial<IndividualSSNParty>
  | Partial<IndividualTINParty>
  | Partial<EntityParty>
  | null
  | undefined;

export type PartialParty = Exclude<CurrentParty, null>;

const borrowerFilter = (
  dealPartyRelations: DealPartyRelation[] | undefined
) => {
  const borrowerRoleTypes = [
    LoanPartyEntityRoleType.Borrower,
    LoanPartyEntityRoleType.PrimaryBorrower
  ];
  return (p: CurrentParty | PartialParty): boolean => {
    return (
      p?.PartyType === PartyType.Entity &&
      !!dealPartyRelations &&
      dealPartyRelations.some(
        (dpr) =>
          dpr?.relationRoleType &&
          dpr.partyId === p.id &&
          intersection(
            borrowerRoleTypes,
            dpr?.relationRoleType as LoanPartyEntityRoleType[]
          )?.length > 0
      )
    );
  };
};

export const BorrowingEntity = (props: {
  currentParties: CurrentParty[];
  includeIndividuals?: boolean;
}) => {
  const { currentParties } = props;

  return (
    <Tooltip
      label={currentParties
        .filter(
          (p) => p?.PartyType === PartyType.Entity || props.includeIndividuals
        )
        .map((p: Party) =>
          p.PartyType === PartyType.Entity
            ? p.FullName
            : `${p.FirstName} ${p.LastName}`
        )
        .join(", ")}
    >
      <Box>
        {currentParties &&
          currentParties
            .filter(
              (p) =>
                p?.PartyType === PartyType.Entity || props.includeIndividuals
            )
            .map((p: Party, i) => (
              <Box
                key={i}
                borderTopWidth={i > 0 ? "2px" : ""}
                borderTopColor={i > 0 ? "grey.100" : ""}
              >
                <Text whiteSpace={"normal"}>
                  {p.PartyType === PartyType.Entity
                    ? p.FullName
                    : `${p.FirstName} ${p.LastName}`}
                </Text>
              </Box>
            ))}
      </Box>
    </Tooltip>
  );
};

export const BorrowingEntityV2 = (props: {
  currentParties: CurrentParty[];
  includeIndividuals?: boolean;
  dealPartyRelations?: DealPartyRelation[];
}) => {
  const { currentParties, dealPartyRelations } = props;
  return (
    <Tooltip
      label={currentParties
        .filter(borrowerFilter(dealPartyRelations))
        .map((p: Party) =>
          p.PartyType === PartyType.Entity
            ? p.FullName
            : `${p.FirstName} ${p.LastName}`
        )
        .join(", ")}
    >
      <Box>
        {currentParties &&
          currentParties
            .filter(borrowerFilter(dealPartyRelations))
            .map((p: Party, i) => (
              <Box
                key={i}
                borderTopWidth={i > 0 ? "2px" : ""}
                borderTopColor={i > 0 ? "grey.100" : ""}
              >
                <Text whiteSpace={"normal"}>
                  {p.PartyType === PartyType.Entity
                    ? p.FullName
                    : `${p.FirstName} ${p.LastName}`}
                </Text>
              </Box>
            ))}
      </Box>
    </Tooltip>
  );
};

export const BorrowingEntityMilestone = (props: {
  currentParties: PartialParty[];
  includeIndividuals?: boolean;
  dealPartyRelations?: DealPartyRelation[];
}) => {
  const { currentParties, dealPartyRelations } = props;
  const filteredParties =
    currentParties &&
    currentParties
      .filter(borrowerFilter(dealPartyRelations))
      .map((p: Party) =>
        p.PartyType === PartyType.Entity
          ? `${p.FullName}, ${p.PartyMilestone || "no milestone"}`
          : `${p.FirstName} ${p.LastName}, ${p.LOCStatusType || "no milestone"}`
      );
  return (
    <Box>
      <Text fontSize="md" w="100%" whiteSpace={"nowrap"} color="gray.800">
        <b>{"Primary Borrower Milestone"}</b>:{" "}
        {filteredParties.length > 0
          ? filteredParties.join(" | ")
          : NOT_AVAILABLE}
      </Text>
    </Box>
  );
};
export const Sponsor = (props: {
  currentParties: CurrentParty[];
  dealPartyRelations: {
    ids: string[];
    entities: {
      [id: string]: DealPartyRelation;
    };
  };
}) => {
  const { currentParties, dealPartyRelations } = props;
  const sponsorRoleTypes = [
    LoanPartyIndividualRoleType.Sponsor,
    LoanPartyIndividualRoleType.PrimarySponsor
  ];
  const sponsorFilter = (p: CurrentParty): boolean => {
    return (
      p?.PartyType === PartyType.Individual &&
      Object.values(dealPartyRelations.entities).some(
        (dpr) =>
          dpr?.relationRoleType &&
          dpr.partyId === p.id &&
          intersection(
            sponsorRoleTypes,
            dpr?.relationRoleType as LoanPartyIndividualRoleType[]
          )?.length > 0
      )
    );
  };
  return (
    <Tooltip
      label={currentParties
        .filter(sponsorFilter)
        .map((p: Party) =>
          p.PartyType === PartyType.Entity
            ? p.FullName
            : `${p.FirstName} ${p.LastName}`
        )
        .join(", ")}
    >
      <Box>
        {currentParties &&
          currentParties.filter(sponsorFilter).map((p: Party, i) => (
            <Box
              key={i}
              borderTopWidth={i > 0 ? "2px" : ""}
              borderTopColor={i > 0 ? "grey.100" : ""}
            >
              <Text whiteSpace={"normal"}>
                {p.PartyType === PartyType.Entity
                  ? p.FullName
                  : `${p.FirstName} ${p.LastName}`}
              </Text>
            </Box>
          ))}
      </Box>
    </Tooltip>
  );
};

export const getPartyNamesText = (
  parties: (Party | null | undefined)[]
): string[] => {
  const partyNames = parties.map((p) => {
    const partyName = getPartyName(p);
    return `Party: ${partyName}`;
  });
  return partyNames;
};

export const PartyNames = (props: {
  parties: (Party | null | undefined)[];
}) => {
  const { parties } = props;
  const partyNames = getPartyNamesText(parties);
  return (
    <Box>
      {partyNames.map((p, i) => (
        <Box
          key={i}
          borderTopWidth={i > 0 ? "2px" : ""}
          borderTopColor={i > 0 ? "grey.100" : ""}
        >
          <Text align="center" whiteSpace={"normal"}>{`${p}`}</Text>
        </Box>
      ))}
    </Box>
  );
};

export const getDealPartyNameText = (
  deal: "" | 0 | WritableDraft<Deal> | undefined | null,
  party: "" | 0 | WritableDraft<Party> | undefined | null
): {
  deal: string;
  party: string;
} => {
  return {
    deal: `Deal: ${!!deal ? deal?.LoanIdentifier : NOT_AVAILABLE}`,
    party: `Party: ${!!party ? getPartyName(party) : NOT_AVAILABLE}`
  };
};

export const DealPartyName = (props: {
  deal: "" | 0 | WritableDraft<Deal> | undefined | null;
  party: "" | 0 | WritableDraft<Party> | undefined | null;
}) => {
  const { deal, party } = props;
  const dealPartyNameText = getDealPartyNameText(deal, party);
  return (
    <Box>
      <Box borderBottomWidth={"2px"} borderBottomColor={"grey.100"}>
        <Text align="center" whiteSpace={"normal"}>
          {dealPartyNameText.deal}
        </Text>
      </Box>
      <Text align="center" whiteSpace={"normal"}>
        {dealPartyNameText.party}
      </Text>
    </Box>
  );
};

export const getDealPropertyNameText = (
  deal: "" | 0 | WritableDraft<Deal> | undefined | null,
  property: "" | 0 | WritableDraft<Property> | undefined | null
): {
  deal: string;
  property: string;
} => {
  return {
    deal: `Deal: ${!!deal ? getDealIdentifier(deal) : NOT_AVAILABLE}`,
    property: `Property: ${
      !!property ? getPropertyAddress(property) : NOT_AVAILABLE
    }`
  };
};

export const DealPropertyName = (props: {
  deal: "" | 0 | WritableDraft<Deal> | undefined | null;
  property: "" | 0 | WritableDraft<Property> | undefined | null;
}) => {
  const { deal, property } = props;
  const dealPropertyNameText = getDealPropertyNameText(deal, property);
  return (
    <Box>
      <Box borderBottomWidth={"2px"} borderBottomColor={"grey.100"}>
        <Text align="center" whiteSpace={"normal"}>
          {`${dealPropertyNameText.deal}`}
        </Text>
      </Box>
      <Text align="center" whiteSpace={"normal"}>
        {`${dealPropertyNameText.property}`}
      </Text>
    </Box>
  );
};

const assetNameTextFromAssetType = {
  [AssetType.Account]: "accountName"
};
export const getAssetNameText = (
  asset: WritableDraft<Asset> | undefined
): {
  asset: string;
} => {
  return {
    asset: `Asset: ${
      (!!asset &&
        (asset[assetNameTextFromAssetType?.[asset.accountType]] ||
          asset?.accountType)) ||
      "N/A"
    }`
  };
};

export const AssetName = (props: {
  asset: WritableDraft<Asset> | undefined;
  parties: (WritableDraft<Party> | undefined)[];
}) => {
  const { asset, parties } = props;
  let assetNameText;
  if (asset) assetNameText = assetToString({ asset, parties });
  else assetNameText = "N/A";
  return (
    <Box>
      <Text align="center" whiteSpace={"normal"}>
        {`${assetNameText}`}
      </Text>
    </Box>
  );
};

export const getStatementNameText = (
  statement: WritableDraft<Statement> | undefined
): {
  statement: string;
} => {
  return {
    statement: `Statement Balance: ${
      (!!statement && statement.statementBalance) || "N/A"
    }`
  };
};

export const StatementName = (props: {
  statement: WritableDraft<Statement> | undefined;
  asset: WritableDraft<Asset> | undefined;
  parties: (WritableDraft<Party> | undefined)[];
}) => {
  const { statement, asset, parties } = props;
  const statementAndAssetText =
    asset && statement && statementToString({ statement, asset, parties });
  return (
    <Box>
      <Text align="center" whiteSpace={"normal"}>
        {`${statementAndAssetText}`}
      </Text>
    </Box>
  );
};
