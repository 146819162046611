import {
  AggregationFocusType,
  AggregationType,
  BaseEntity,
  FOCUSED,
  FieldType
} from "@elphi/types";
import { concat, entries, get, isEmpty } from "lodash";
import {
  OnChangeInput,
  Section
} from "../../../components/form-builder/FormBuilder";
import { EntityFormFieldSpecs } from "../../../components/form-builder/field-specs/fields.types";
import { buildInputs } from "../../../components/form-builder/formBuilder.utils";
import { BaseInputFormatter } from "../../../components/form-builder/formatters/formatter.types";
import { InputRule, Predicate } from "./formBuilderSectionProvider.types";

export const buildInput = (r: {
  fieldKey?: string[];
  validation?: (v: any, isRequired?: boolean) => boolean;
  calculationType?: AggregationType;
  isHidden?: boolean;
  isReadOnly?: boolean;
  formatter?: BaseInputFormatter<FieldType, any, any>;
  isRequired?: boolean;
}) => {
  const {
    fieldKey,
    calculationType,
    validation,
    isHidden,
    isReadOnly,
    isRequired,
    formatter
  } = r;

  const path = calculationType
    ? fieldKey?.slice(0, fieldKey.length - 1)
    : fieldKey;

  return {
    path,
    validation,
    isHidden,
    isRequired,
    isReadOnly,
    formatter,
    isAggregation: calculationType
  };
};

export const buildSection = (r: {
  isHidden?: boolean;
  state: Partial<BaseEntity<object>>;
  onChange?: (v: OnChangeInput) => void;
  header: string;
  inputs: ReturnType<typeof buildInput>[];
  fieldSpecs: EntityFormFieldSpecs<object>;
  hideAttachedComponent?: boolean;
  extraBody?: React.ReactElement;
  id?: string;
  prefixPathToValue?: string[];
}): Section => {
  const {
    state,
    onChange,
    header,
    inputs,
    fieldSpecs,
    extraBody,
    id,
    hideAttachedComponent,
    prefixPathToValue
  } = r;
  return {
    header,
    id,
    extraBody,
    isHidden: r.isHidden,
    inputs: buildInputs({
      prefixPathToValue,
      hideAttachedComponent,
      state,
      onChange,
      fieldSpecs,
      specs: addRulesToInputs({
        inputs,
        state,
        rules: [
          {
            field: "isReadOnly",
            predicate: predicates.isCalcFieldReadOnly
          }
        ]
      })
    })
  };
};

export const buildEmptySection = () => ({
  header: "",
  id: "",
  extraBody: undefined,
  isHidden: false,
  inputs: []
});
export const predicates: { [key: string]: Predicate } = {
  isCalcFieldReadOnly: (r: {
    state: Partial<BaseEntity<object>>;
    input: ReturnType<typeof buildInput>;
  }) =>
    r.input.isAggregation
      ? get(r.state, concat(r.input.path || [], FOCUSED)) !==
        AggregationFocusType.Override
      : false
};

export const addRuleToInput = (r: {
  input: ReturnType<typeof buildInput>;
  state: Partial<BaseEntity<object>>;
  rules: InputRule[];
}): typeof r.input => {
  const { input, state, rules } = r;
  const rulesObj = rules.reduce((acc, rule) => {
    if (rule.field === "formatter") {
      return {
        ...acc,
        formatter: rule.formatter(get(state, input.path || []))
      };
    }
    if (rule.field === "validation") {
      return {
        ...acc,
        validation: () => rule.validation(get(state, input.path || []))
      };
    }
    if (rule.field === "isReadOnly") {
      return {
        ...acc,
        isReadOnly: rule.predicate({ state, input })
      };
    }
    if (rule.field === "isHidden") {
      return {
        ...acc,
        isHidden: rule.predicate({ state, input })
      };
    }
    if (rule.field === "isRequired") {
      return {
        ...acc,
        isRequired: rule.predicate({ state, input })
      };
    }
    return acc;
  }, {} as typeof input);
  return {
    ...input,
    ...rulesObj
  };
};

export const addRulesToInputs = (r: {
  inputs: ReturnType<typeof buildInput>[];
  state: Partial<BaseEntity<object>>;
  rules: InputRule[];
}): typeof r.inputs => {
  const { inputs, state, rules } = r;
  return inputs.map((input) => addRuleToInput({ input, state, rules }));
};

export const injectSpecToBuilder = <T>(r: {
  spec: EntityFormFieldSpecs<object>;
  builders: Record<string, Function>;
}) => {
  const { spec, builders } = r;
  if (isEmpty(spec)) {
    return {} as T;
  }

  return entries(builders).reduce((acc, [key, builder]) => {
    acc[key] = builder(spec);
    return acc;
  }, {} as T);
};
