import { Box, Progress, Tooltip } from "@chakra-ui/react";
import {
  Deal,
  FieldType,
  Party,
  PartyRelation,
  PartyType,
  SystemSetup
} from "@elphi/types";
import { EntityState } from "@reduxjs/toolkit";
import { Omit } from "lodash";
import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { AppConfig } from "../../config/appConfig";
import { useSections } from "../../forms/schemas/providers/SectionsProvider";
import { getDomainOwner } from "../../forms/schemas/utils/formCommon.utils";
import { useNavigationHooks } from "../../hooks/navigation.hooks";
import { usePartyHooks } from "../../hooks/party.hooks";
import { usePartyRelationHooks } from "../../hooks/partyrelation.hooks";
import { useSnapshotHooks } from "../../hooks/snapshot.hooks";
import { RootState } from "../../redux/store";
import { NavigationPath } from "../../shared/types/navigation.types";
import AccordionSections from "../ScrollableSections";
import { CreditReportHistoryTableContainer } from "../credit-report/CreditReportHistoryTable";
import { DealPartyRelationFormContainer } from "../deal/DealPartyRelationForm";
import FormBuilder, {
  Section,
  SectionHeader
} from "../form-builder/FormBuilder";
import { useFormBuilderStateHandler } from "../form-builder/InputBuilder";
import AssetPageContainer from "../party/AssetPageContainer";
import { PartyRelationStructureState } from "../party/PartyStructure";
import { partyName } from "../party/utils/printUtils";
import { ServiceProviderPartyContainer } from "../rolodex";
import { useElphiToast } from "../toast/toast.hook";
import { TabFormProps } from "./TabForm";
import { ExpandSections } from "./sections/expandSections";

export type PartyTabFormProps = Omit<TabFormProps, "sections"> & {
  sections: {
    sections: (Section | undefined)[];
    afterTableSections?: (Section | undefined)[];
    expandSections: ExpandSections;
  } & {
    navigationPath?: NavigationPath;
  };
};
export const PartyTabFormContainer = (
  props: PartyTabFormProps & { snapshotId?: string }
) => {
  return props.snapshotId ? (
    <PartyTabFormSnapshotContainer {...props} snapshotId={props.snapshotId!} />
  ) : (
    <PartyTabFormLiveStateContainer {...props} />
  );
};

const PartyTabFormSnapshotContainer = (
  props: PartyTabFormProps & { snapshotId: string }
) => {
  const { snapshotDataState } = useSnapshotHooks();
  const { partyTab, selectedPartyTabRelationId } = usePartyHooks();
  const snapshot = snapshotDataState({ snapshotId: props.snapshotId });
  const dealSlice = snapshot.dealState;
  const partySlice = snapshot.partyState;
  const partyRelationSlice = snapshot.partyRelationState;
  const highlightedParty = useMemo(
    () =>
      partyTab &&
      partyTab?.highlightedPartyId &&
      partySlice?.entities?.[partyTab.highlightedPartyId],
    [partyTab?.highlightedPartyId]
  );
  const selectedParent = useMemo(
    () =>
      partyTab &&
      partyTab?.highlightedPartyParentId &&
      partySlice?.entities?.[partyTab.highlightedPartyParentId],
    [partyTab?.highlightedPartyParentId]
  );
  const selectedPartyRelationState = useMemo(
    () =>
      selectedPartyTabRelationId &&
      partyRelationSlice?.entities?.[selectedPartyTabRelationId],
    [selectedPartyTabRelationId]
  );

  //TODO: validate dealSlice and partySlice exists
  const tabFormMemo = useMemo(() => {
    return (
      <PartyTabForm
        {...props}
        snapshotId={props.snapshotId}
        dealSlice={dealSlice!}
        partySlice={partySlice!}
        highlightedParty={highlightedParty || undefined}
        selectedParent={selectedParent || undefined}
        selectedPartyRelationState={selectedPartyRelationState || undefined}
        updatePartyRelationBatch={(_: any) => {
          return {} as any;
        }}
      />
    );
  }, [
    dealSlice,
    partySlice,
    partyRelationSlice,
    highlightedParty,
    selectedParent,
    selectedPartyRelationState
  ]);
  return tabFormMemo;
};
const PartyTabFormLiveStateContainer = (
  props: PartyTabFormProps & { snapshotId?: string }
) => {
  const dealSlice = useSelector((state: RootState) => state.deal);
  const {
    partyTabHighlightedParty: highlightedParty,
    partyTabHighlightedPartyParent: selectedParent,
    selectedPartyTabRelation: selectedPartyRelationState,
    partyState: partySlice
  } = usePartyHooks();

  const { updateBatch: updatePartyRelationBatch } = usePartyRelationHooks();
  return (
    <PartyTabForm
      {...props}
      dealSlice={dealSlice}
      highlightedParty={highlightedParty}
      selectedParent={selectedParent}
      selectedPartyRelationState={selectedPartyRelationState}
      partySlice={partySlice}
      updatePartyRelationBatch={updatePartyRelationBatch}
    />
  );
};
const PartyTabForm = (
  props: PartyTabFormProps & {
    snapshotId?: string;
    dealSlice: EntityState<Deal>;
    partySlice: EntityState<Party>;
    highlightedParty?: Party;
    selectedParent?: Party;
    selectedPartyRelationState?: PartyRelation;
    updatePartyRelationBatch?: ReturnType<
      typeof usePartyRelationHooks
    >["updateBatch"];
  }
) => {
  const { errorToast, successToast } = useElphiToast();
  const {
    dealSlice,
    highlightedParty,
    selectedParent,
    selectedPartyRelationState,
    updatePartyRelationBatch
  } = props;
  const { navigationState } = useNavigationHooks();

  const { resetPartySelections } = usePartyHooks();
  const updatePartyRelationHandler = async (
    diff: Partial<PartyRelationStructureState>
  ) => {
    if (!updatePartyRelationBatch) return null;
    if (!diff.relations) return null;
    const relations = Object.keys(diff.relations)
      .map((id) => {
        if (diff.relations) {
          return {
            ...diff.relations[id],
            id
          };
        }
      })
      .filter((v) => v !== undefined);
    if (!relations.length) return null;

    return await updatePartyRelationBatch({ relations } as {
      relations: ({
        id: string;
      } & Partial<PartyRelation>)[];
    }).then((r) => {
      if (r.status === 200) {
        successToast({
          title: "Party Relations Updated",
          description: `${r.data.batch.length} relations updated`
        });
        setRelationDiffState({ relations: {} });
      }
      if (r.status === 400) {
        errorToast({
          title: "Failed to update party relations",
          description: r.data.description
        });
      }
      return r;
    });
  };
  const {
    onChange: relationOnChange,
    state: relationState,
    setDiffState: setRelationDiffState,
    // diffState: relationDiffState,
    syncState: partyRelationSync
    // setState: setRelationState
  } = useFormBuilderStateHandler<PartyRelationStructureState>({
    initialState: { relations: {} } as PartyRelationStructureState,
    callback: updatePartyRelationHandler,
    callbackOptions: {
      debounceRate: AppConfig.debounceRate,
      clearDiff: true
    }
  });
  useEffect(() => {
    partyRelationSync({
      shouldSync: !!props.selectedPartyRelationState?.id,
      state: props.selectedPartyRelationState,
      statePath: () => {
        return ["relations", selectedPartyRelationState!.id];
      }
    });
  }, [selectedPartyRelationState]);

  const currentPartyRelation = selectedPartyRelationState
    ? relationState.relations[selectedPartyRelationState!.id]
    : ({} as PartyRelation);
  const fullPartyRelationOnChange = (v: {
    fieldType: FieldType;
    fieldKey: string[];
    value: any;
  }) =>
    selectedPartyRelationState &&
    relationOnChange({
      fieldType: v.fieldType,
      fieldKey: ["relations", selectedPartyRelationState.id, ...v.fieldKey],
      value: v.value
    });
  const SECTION_HEADER_OFFSET = "60px";
  const { sections } = useSections();

  const relationSection = useMemo(() => {
    return (
      highlightedParty &&
      selectedParent && (
        <Tooltip
          isDisabled={!!selectedPartyRelationState}
          label={"no relation was selected"}
          placement="auto"
        >
          <Box>
            <FormBuilder
              customKey="partyRelationSectionTab"
              top={SECTION_HEADER_OFFSET}
              isDisabled={!selectedPartyRelationState || !!props.snapshotId}
              onChange={fullPartyRelationOnChange}
              sections={[
                sections?.partyRelation?.partyRelationSection?.({
                  state: currentPartyRelation,
                  onChange: fullPartyRelationOnChange,
                  options: {
                    hideAttachedComponent: !selectedPartyRelationState,
                    isReadOnly: !!props.snapshotId,
                    header:
                      selectedParent && selectedPartyRelationState
                        ? ` Relation: ${partyName(
                            selectedParent
                          )} ~ ${partyName(highlightedParty)}`
                        : "Relation"
                  }
                })
              ]}
              navigationPath={props.navigationPath}
            />
          </Box>
        </Tooltip>
      )
    );
  }, [
    highlightedParty,
    relationState,
    selectedPartyRelationState,
    props.snapshotId,
    navigationState
  ]);

  useEffect(() => {
    return () => {
      resetPartySelections();
    };
  }, []);

  const domainOwner = getDomainOwner();
  const shouldShowCreditReportHistory =
    highlightedParty?.PartyType === PartyType.Individual &&
    domainOwner === SystemSetup.Lima;

  return (
    <Box h="100%" w="100%">
      {props.isLoading && (
        <Box w="100%">
          <Progress size="xs" isIndeterminate />
        </Box>
      )}

      {props.dealId &&
        dealSlice?.entities[props.dealId] &&
        highlightedParty && (
          <Box h="100%" w="100%">
            <DealPartyRelationFormContainer
              dealId={props.dealId}
              snapshotId={props.snapshotId}
              expandDealPartyRelationshipSection={
                props.sections?.expandSections?.DealPartyRelationship
                  ?.isExpanded ?? false
              }
              navigationPath={props.navigationPath}
            />
            {relationSection}
            <FormBuilder
              customKey="sectionsPartyTab"
              top={SECTION_HEADER_OFFSET}
              onChange={props.onChange}
              sections={props.sections?.sections ?? []}
              navigationPath={props.navigationPath}
            />
            {highlightedParty.PartyType === PartyType.Individual && (
              <AccordionSections
                customKey="serviceProvider"
                top={SECTION_HEADER_OFFSET}
                sections={[
                  {
                    header: <SectionHeader header={"Service Providers"} />,
                    body: (
                      <ServiceProviderPartyContainer
                        dealId={props.dealId}
                        partyId={highlightedParty?.id}
                      />
                    )
                  }
                ]}
                navigationPath={props.navigationPath}
              />
            )}
            {highlightedParty.PartyType === PartyType.Individual && (
              <AccordionSections
                customKey="assetsParty"
                top={SECTION_HEADER_OFFSET}
                sections={[
                  {
                    header: <SectionHeader header="Assets" />,
                    body: (
                      <AssetPageContainer
                        selectedParty={highlightedParty}
                        snapshotId={props.snapshotId}
                      />
                    )
                  }
                ]}
                navigationPath={props.navigationPath}
              />
            )}
            {shouldShowCreditReportHistory && (
              <AccordionSections
                customKey="creditReportHistoryParty"
                top={SECTION_HEADER_OFFSET}
                sections={[
                  {
                    header: <SectionHeader header="Credit Report History" />,
                    body: (
                      <CreditReportHistoryTableContainer
                        selectedParty={highlightedParty}
                        snapshotId={props.snapshotId}
                        includeManualCreate={true}
                      />
                    )
                  }
                ]}
                navigationPath={props.navigationPath}
              />
            )}
            {props?.sections?.afterTableSections && (
              <FormBuilder
                customKey="afterTableSections"
                top={SECTION_HEADER_OFFSET}
                onChange={props.onChange}
                sections={props?.sections?.afterTableSections ?? []}
                navigationPath={props.navigationPath}
              />
            )}
          </Box>
        )}
    </Box>
  );
};

export default PartyTabForm;
