import { Box, Button, Flex, Progress } from "@chakra-ui/react";
import {
  DataSheetCellMetadata,
  PortfolioTemplateType,
  Property,
  PropertyCellData,
  SelectedCells
} from "@elphi/types";
import { get } from "lodash";
import { useCallback, useMemo, useRef, useState } from "react";
import ReactDataSheet from "react-datasheet";
import elphiTheme from "../../assets/themes/elphi.theme.default";
import { DataSheetCellState } from "../data-sheet/dataSheet.types";
import { OnChangeInput } from "../form-builder/FormBuilder";
import { FieldType } from "../form-builder/fieldFormat.types";
import PortfolioPageHeader from "./PortfolioPageHeader";
import {
  CellRenderer,
  CellRendererProps,
  RowRenderer,
  RowRendererProps,
  SheetRenderer,
  SheetRendererProps,
  valueRenderer
} from "./data-sheet/renderer";
import {
  fieldEffectFieldMap,
  fieldToCalculation,
  getTemplateFieldMap,
  getTemplateKeys,
  portfolioTemplateToFieldTypes,
  portfolioTypeToHeader
} from "./utils/portfolio.utils";

const PAGE_SIZE = 20;

export type PortfolioPageProps = {
  //dealId: string;
  dealProperties: Property[];
  isReadOnlyMode: boolean;
  updatePropertyBatch?: (v: Partial<PortfolioPagePropertiesState>) => void;
  updatePropertyBatchResponseIsLoading?: boolean;
  diffState?: Partial<PortfolioPagePropertiesState>;
  uploadResponseIsLoading?: boolean;
  onUploadPortfolioHandler?: (file: File) => void;
  actionHandler?: (r: {
    newState: DataSheetCellState;
    iterateSelectedCells: (
      selectedCells: SelectedCells | undefined,
      cellCallback: (cell: PropertyCellData) => void
    ) => void;
  }) => void;
  overrideHandler?: (r: {
    iterateSelectedCells: (
      selectedCells: SelectedCells | undefined,
      cellCallback: (cell: PropertyCellData) => void
    ) => void;
  }) => void;
  dealPropertiesResponseIsFetching?: boolean;
  dealPropertiesResponseIsSuccess?: boolean;
  onChange?: (v: OnChangeInput) => void;
  debounceCallback?: (selection: SelectedCells) => void;
};

export type PortfolioPagePropertiesState = { [id: string]: Property };

const PortfolioPage = (props: PortfolioPageProps) => {
  const {
    dealProperties,
    isReadOnlyMode,
    updatePropertyBatch,
    updatePropertyBatchResponseIsLoading,
    diffState,
    uploadResponseIsLoading,
    onUploadPortfolioHandler,
    actionHandler,
    overrideHandler,
    dealPropertiesResponseIsFetching,
    dealPropertiesResponseIsSuccess,
    onChange,
    debounceCallback
  } = props;
  const [pageIndex, setPageIndex] = useState(1);
  const dataSheetRef = useRef(null);

  const [selectedPortfolioTemplate, setSelectedPortfolioTemplate] = useState(
    PortfolioTemplateType.LongTerm
  );

  const gridData = useMemo(() => {
    if (!!dealProperties.length) {
      return dealProperties
        .slice((pageIndex - 1) * PAGE_SIZE, pageIndex * PAGE_SIZE)
        .map((p) => {
          if (!p) return [];

          const portfolioToPropertyFieldMap = getTemplateFieldMap({
            type: selectedPortfolioTemplate
          });
          const portfolioTemplateKeys = getTemplateKeys({
            type: selectedPortfolioTemplate
          });
          return portfolioTemplateKeys.map((k) => {
            const v = get(p, portfolioToPropertyFieldMap[k]);
            const diffValue = get(diffState, [
              p.id,
              ...portfolioToPropertyFieldMap[k]
            ]);
            const metadata =
              (diffState && diffState[p.id]?.SheetMetadata?.[k]) ||
              p.SheetMetadata?.[k] ||
              ({
                state: DataSheetCellState.None,
                override: false
              } as DataSheetCellMetadata);
            return {
              propertyId: p.id,
              value: diffValue ?? (v || ""),
              key: k,
              fieldPath: portfolioToPropertyFieldMap[k],
              override: metadata.override,
              state: metadata.state,
              readOnly: isReadOnlyMode
            };
          });
        })
        .filter((v) => v.length > 0);
    } else {
      return [];
    }
  }, [dealProperties, diffState, pageIndex]);

  const iterateSelectedCells = (
    selectedCells: SelectedCells | undefined,
    cellCallback: (cell: PropertyCellData) => void
  ) => {
    if (!selectedCells || !selectedCells?.start || !selectedCells?.end) return;
    const start = selectedCells?.start;
    const end = selectedCells?.end;

    if (end.i < start.i) {
      let temp = start.i;
      start.i = end.i;
      end.i = temp;
    }
    if (end.j < start.j) {
      let temp = start.j;
      start.j = end.j;
      end.j = temp;
    }
    for (let i = start.i; i <= end.i; i++) {
      for (let j = start.j; j <= end.j; j++) {
        const cell = gridData[i][j];
        cellCallback(cell);
      }
    }
  };

  const cellRendererCB = useCallback((props: CellRendererProps) => {
    return <CellRenderer {...props} />;
  }, []);

  const sheetRendererCB = useCallback((props: SheetRendererProps) => {
    return <SheetRenderer {...props} />;
  }, []);
  const rowRendererCB = useCallback(
    (props: RowRendererProps) => {
      return (
        <RowRenderer {...props} row={props.row + (pageIndex - 1) * PAGE_SIZE} />
      );
    },
    [pageIndex]
  );

  const OFFSET_HEADER_HEIGHT = "160px";

  return (
    <Box
      style={{
        overflowY: "scroll",
        width: "100%",
        height: "100%"
      }}
    >
      <Box position="sticky" top="0" bgColor="white" zIndex="2" w="100%">
        <PortfolioPageHeader
          isDisabled={isReadOnlyMode}
          save={{
            isLoading: updatePropertyBatchResponseIsLoading || false,
            onClick: () => {
              updatePropertyBatch &&
                diffState &&
                updatePropertyBatch(diffState);
            }
          }}
          fileUpload={{
            isLoading: uploadResponseIsLoading || false,
            onFileUpload: (file: File) => {
              onUploadPortfolioHandler && onUploadPortfolioHandler(file);
            }
          }}
          template={{
            selectedPortfolioTemplate,
            setSelectedPortfolioTemplate
          }}
          actions={[
            {
              label: "valid",
              themeStyle:
                elphiTheme.components.light.button.portfolioAction.valid,
              onClick: () => {
                actionHandler &&
                  actionHandler({
                    newState: DataSheetCellState.Valid,
                    iterateSelectedCells: iterateSelectedCells
                  });
              },
              isDisabled: isReadOnlyMode
            },
            {
              label: "follow up",
              themeStyle:
                elphiTheme.components.light.button.portfolioAction.followup,
              onClick: () => {
                actionHandler &&
                  actionHandler({
                    newState: DataSheetCellState.FollowUp,
                    iterateSelectedCells: iterateSelectedCells
                  });
              },
              isDisabled: isReadOnlyMode
            },
            {
              label: "deny",
              themeStyle:
                elphiTheme.components.light.button.portfolioAction.deny,
              onClick: () => {
                actionHandler &&
                  actionHandler({
                    newState: DataSheetCellState.Denied,
                    iterateSelectedCells: iterateSelectedCells
                  });
              },
              isDisabled: isReadOnlyMode
            },
            {
              label: "clear",
              themeStyle:
                elphiTheme.components.light.button.portfolioAction.none,
              onClick: () => {
                actionHandler &&
                  actionHandler({
                    newState: DataSheetCellState.None,
                    iterateSelectedCells: iterateSelectedCells
                  });
              },
              isDisabled: isReadOnlyMode
            },
            {
              label: "remove override",
              themeStyle:
                elphiTheme.components.light.button.portfolioAction.override,
              onClick: () => {
                overrideHandler &&
                  overrideHandler({
                    iterateSelectedCells: iterateSelectedCells
                  });
              },
              isDisabled: isReadOnlyMode
            }
          ]}
        />
        <Flex pt="20px">
          <Box pl="5px">
            <Button
              isDisabled={pageIndex <= 1}
              onClick={() => {
                setPageIndex(pageIndex - 1);
              }}
            >
              Previous
            </Button>
          </Box>
          <Box pl="10px">
            <Button
              isDisabled={
                !dealProperties ||
                pageIndex * PAGE_SIZE >= dealProperties.length
              }
              onClick={() => {
                setPageIndex(pageIndex + 1);
              }}
            >
              Next
            </Button>
          </Box>
        </Flex>
      </Box>
      <Box
        overflowX="scroll"
        width="100%"
        h={`calc(100% - ${OFFSET_HEADER_HEIGHT})`}
      >
        {(dealPropertiesResponseIsFetching ?? false) && (
          <Progress size="xs" isIndeterminate w="100%" />
        )}
        {(dealPropertiesResponseIsSuccess ?? true) && (
          <ReactDataSheet
            ref={dataSheetRef}
            data={gridData}
            valueRenderer={valueRenderer}
            sheetRenderer={(props: SheetRendererProps) =>
              sheetRendererCB({
                ...props,
                header: portfolioTypeToHeader(selectedPortfolioTemplate)
              })
            }
            cellRenderer={(props: CellRendererProps) => {
              const cellRendererProps = {
                ...props,
                isEditModeCell: !isReadOnlyMode
              };
              return !!onChange
                ? cellRendererCB({ ...cellRendererProps, onChange })
                : cellRendererCB({ ...cellRendererProps });
            }}
            rowRenderer={rowRendererCB}
            onCellsChanged={(changes) => {
              changes.forEach((change) => {
                if (onChange && change.cell) {
                  //initial change set
                  onChange({
                    fieldType: portfolioTemplateToFieldTypes[change.cell.key],
                    value: change.value ?? change.cell.value,
                    fieldKey: [change.cell.propertyId, ...change.cell.fieldPath]
                  });

                  //if field effects calculated values
                  if (fieldEffectFieldMap[change.cell.key]?.length > 0) {
                    const calculationFunc =
                      fieldToCalculation[
                        fieldEffectFieldMap[change.cell.key][0]
                      ];
                    if (calculationFunc) {
                      const newValue = calculationFunc({
                        state: dealProperties,
                        changedCell: change.cell,
                        diffState
                      });

                      //set calculated value
                      onChange({
                        fieldType:
                          portfolioTemplateToFieldTypes[
                            fieldEffectFieldMap[change.cell.key][0]
                          ],
                        fieldKey: [
                          change.cell!.propertyId,
                          fieldEffectFieldMap[change.cell.key][0]
                        ],
                        value: newValue
                      });

                      //reset override state
                      onChange({
                        fieldType: FieldType.String,
                        fieldKey: [
                          change.cell!.propertyId,
                          "SheetMetadata",
                          fieldEffectFieldMap[change.cell.key][0],
                          "override"
                        ],
                        value: false
                      });
                    }
                  }
                  //if chaged field is a calculated value - override
                  else if (fieldToCalculation[change.cell.key]) {
                    onChange({
                      fieldType: FieldType.String,
                      value: true,
                      fieldKey: [
                        change.cell!.propertyId,
                        "SheetMetadata",
                        change.cell.key,
                        "override"
                      ]
                    });
                  }
                }
              });
            }}
            onSelect={(selection) => {
              debounceCallback && debounceCallback(selection);
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default PortfolioPage;
