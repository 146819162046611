import {
  Box,
  BoxProps,
  Center,
  Flex,
  Text,
  TextProps,
  Tooltip
} from "@chakra-ui/react";
import { FieldStatus, SectionStatus } from "@elphi/types";
import { isEmpty } from "lodash";
import { useEffect } from "react";
import { fieldStatusToColor } from "../task/field-selection/d2v.utils";
import { InputBuilderSpecs, Section } from "./FormBuilder";

const SectionStatisticsBatteryView = (props: {
  h: BoxProps["h"];
  fieldsStats: {
    Populated: number;
    "Populated Not Valid": number;
    Empty: number;
    Pending: number;
    Approved: number;
    "Attention Required": number;
    Denied: number;
    Total: number;
  };
  statistics: {
    label: string;
    value: string;
  }[];
}) => {
  const { statistics, fieldsStats } = props;
  const BATTERY_WIDTH = 200;
  return (
    <Flex>
      <Box w="100px">
        <StatisticCountView
          label={"Populated"}
          value={fieldsStats.Populated.toString()}
          total={fieldsStats.Total.toString()}
        />
      </Box>

      <Flex h={props.h} justifyContent={"flex-end"} w={`${BATTERY_WIDTH}px`}>
        {statistics.map((stat, i) => {
          const percentageFromTotal = Math.ceil(
            (Number(stat.value) * 100) / fieldsStats.Total
          );
          const widthPercentage = Math.ceil(
            percentageFromTotal * BATTERY_WIDTH
          );
          return (
            <Tooltip
              key={i}
              label={`${stat.label}: ${stat.value} / ${fieldsStats.Total}`}
            >
              <Box
                _hover={{
                  opacity: "0.7"
                }}
                filter={"contrast(1)"}
                bgColor={fieldStatusToColor[stat.label]}
                w={`${widthPercentage}px`}
              ></Box>
            </Tooltip>
          );
        })}
      </Flex>
    </Flex>
  );
};

export const SectionStatisticsCountView = (props: {
  h: BoxProps["h"];
  fieldsStats: {
    Populated: number;
    Empty: number;
    Pending: number;
    Approved: number;
    "Attention Required": number;
    Denied: number;
    Total: number;
  };
  statistics: {
    label: string;
    value: string;
  }[];
}) => {
  const { statistics, fieldsStats } = props;
  return (
    <>
      {statistics.map((stats, i) => {
        return (
          <Box key={i} pr="5px">
            <Box
              // boxShadow={"md"}
              borderRadius={"10px"}
              // border="2px"
              // // filter={"contrast(1.45)"}
              // borderColor={
              //   stats.value === fieldsStats.Total.toString()
              //     ? fieldStatusToColor[stats.label] || "white"
              //     : "white"
              // }
              filter={"contrast(1.45)"}
              bgColor={
                stats.value === fieldsStats.Total.toString()
                  ? fieldStatusToColor[stats.label] || "white"
                  : stats.value > "0" &&
                    stats.label !== FieldStatus.None &&
                    stats.label !== FieldStatus.Approved
                  ? fieldStatusToColor[stats.label]
                  : "white"
              }
            >
              <Flex justifyContent={"space-evenly"}>
                <Center
                  w="25px"
                  h="25px"
                  // borderRadius="50%"
                  // bgColor={fieldStatusToColor[stats.label]}
                >
                  <Text fontSize="16px" fontWeight={"bold"}>
                    {stats.value}
                  </Text>
                </Center>
                /
                <Center w="25px" h="25px" borderRadius="50%">
                  <Text fontSize="16px" fontWeight={"bold"}>
                    {fieldsStats.Total}
                  </Text>
                </Center>
              </Flex>
            </Box>
            <Box w="70px">
              <Center pt="2px">
                {fieldStatusToColor[stats.label] ? (
                  <Box
                    w="20px"
                    h="20px"
                    borderRadius="50%"
                    bgColor={fieldStatusToColor[stats.label]}
                  >
                    <Center>
                      <Text color="white" fontSize="14px" fontWeight={"bold"}>
                        {stats.label === FieldStatus.Approved
                          ? "V"
                          : stats.label[0]}
                      </Text>
                    </Center>
                  </Box>
                ) : (
                  <Text fontSize="12px" fontWeight={"bold"}>
                    {stats.label}
                  </Text>
                )}
              </Center>
            </Box>
          </Box>
        );
      })}
    </>
  );
};
export const StatisticCountView = (props: {
  label: string;
  value: string;
  total?: string;
  bgColor?: BoxProps["bgColor"];
  labelColor?: BoxProps["color"];
  labelBgColor?: BoxProps["bgColor"];
  textLabelColor?: TextProps["color"];
}) => {
  const { bgColor, label, value, total } = props;
  return (
    <Box w="100%">
      <Center>
        <Flex flexDir={"column"}>
          <Box borderRadius={"10px"} filter={"contrast(1)"} bgColor={bgColor}>
            <Flex justifyContent={"space-evenly"}>
              <Center w="25px" h="25px">
                <Text fontSize="16px" fontWeight={"bold"}>
                  {value}
                </Text>
              </Center>
              {total && (
                <>
                  /
                  <Center w="25px" h="25px" borderRadius="50%">
                    <Text fontSize="16px" fontWeight={"bold"}>
                      {total}
                    </Text>
                  </Center>
                </>
              )}
            </Flex>
          </Box>
          <Box
            w="70px"
            borderRadius={"2px"}
            padding="2px"
            color={props.labelColor}
            bgColor={props.labelBgColor}
          >
            <Text
              fontSize="12px"
              fontWeight={"bold"}
              color={props.textLabelColor}
              textAlign={"center"}
            >
              {label}
            </Text>
          </Box>
        </Flex>
      </Center>
    </Box>
  );
};

export const sectionStatistics = (s: { inputs: InputBuilderSpecs[] }) =>
  s.inputs.reduce(
    (p, i) => {
      const {
        currentValue,
        isValid,
        fieldStatus,
        isHidden,
        isRequired,
        fieldKey,
        isAggregation
      } = i;
      const lastFieldKey = fieldKey?.length && [...fieldKey]?.pop();

      if (isHidden) return p;
      p.Populated += (
        !Array.isArray(currentValue) ? !!currentValue : !!currentValue.length
      )
        ? 1
        : 0;

      p["Populated Not Valid"] +=
        !isValid && !isEmpty(currentValue) && !Array.isArray(currentValue)
          ? 1
          : 0;

      p[SectionStatus.InvalidRequired] +=
        isRequired && (isEmpty(currentValue) || !isValid) ? 1 : 0;

      p[SectionStatus.Override] +=
        i.shouldShowFocusBadge && isAggregation && lastFieldKey === "override"
          ? 1
          : 0;

      p.Empty += !currentValue ? 1 : 0;
      p[fieldStatus || FieldStatus.None] += 1;
      p.Total += 1;
      return p;
    },
    {
      Populated: 0,
      "Populated Not Valid": 0,
      Empty: 0,
      [FieldStatus.Approved]: 0,
      [FieldStatus.AttentionRequired]: 0,
      [FieldStatus.Denied]: 0,
      [FieldStatus.None]: 0,
      [SectionStatus.InvalidRequired]: 0,
      [SectionStatus.Override]: 0,
      Total: 0
    }
  );
export const SectionStatistics = (props: {
  id?: string;
  index: number;
  section: Section;
  onSectionValidationStateChange?: (isSectionValid: boolean) => void;
}) => {
  const fieldsStats = sectionStatistics(props.section);

  useEffect(() => {
    if (props.onSectionValidationStateChange) {
      const isSectionValid =
        !fieldsStats["Populated Not Valid"] &&
        !fieldsStats[SectionStatus.InvalidRequired];
      props.onSectionValidationStateChange(isSectionValid);
    }
  }, [
    fieldsStats["Populated Not Valid"],
    fieldsStats[SectionStatus.InvalidRequired]
  ]);
  const statistics = Object.keys(fieldsStats)
    .filter(
      (k) =>
        k !== "Total" &&
        k !== "Empty" &&
        k !== "Populated" &&
        k !== "Populated Not Valid" &&
        k !== SectionStatus.InvalidRequired &&
        k !== SectionStatus.Override
    )
    .map((k) => {
      return {
        label: k,
        value: `${fieldsStats[k]}`
      };
    });
  return (
    <SectionStatisticsBatteryView
      h="20px"
      statistics={statistics}
      fieldsStats={fieldsStats}
    />
  );
};
