import { Box, Button, Flex, Input } from "@chakra-ui/react";
import { removeEmpty } from "@elphi/utils/src/common.utils";
import Quill from "quill";
import "quill-mention/autoregister";
import "quill/dist/quill.snow.css";
import { useEffect, useRef, useState } from "react";
import elphiTheme from "../../assets/themes/elphi.theme.default";
import { EMPTY } from "../../constants/common";
import "./RichTextEditor.css";

import {
  buildRichModeInstance,
  buildViewModeInstance
} from "./quillInstanceBuilder";
import { sanitizeEditorData } from "./reachText.utils";
import { RichTextEditorProps } from "./richText.types";

export const RichTextEditor = (props: RichTextEditorProps) => {
  const {
    mode,
    onSave,
    onCancel,
    openedPlaceholder,
    closedPlaceholder,
    text,
    mentionList
  } = props;
  const [isOpen, setIsOpen] = useState<boolean>();
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const editorRef = useRef<HTMLDivElement>(null);
  const quillRef = useRef<Quill | null>(null);

  useEffect(() => {
    if (editorRef.current) {
      const pl = openedPlaceholder || "Type @ to mention and notify someone";
      if (mode === "create") {
        quillRef.current = buildRichModeInstance({
          ref: editorRef.current,
          placeholder: pl,
          mentionList
        });
        quillRef.current.enable(true);
      }
      if (mode === "edit") {
        quillRef.current = buildRichModeInstance({
          ref: editorRef.current,
          placeholder: pl,
          mentionList
        });
        setIsOpen(true);
        quillRef.current.enable(true);
      }
      if (mode === "view") {
        quillRef.current = buildViewModeInstance({
          ref: editorRef.current,
          placeholder: pl
        });
        setIsOpen(true);
        quillRef.current.disable();
      }
      quillRef.current && text && quillRef.current.setContents(tryParse(text));
    }
    return () => {
      if (quillRef.current) {
        quillRef.current = null;
      }
    };
  }, [mode, text]);

  useEffect(() => {
    if (quillRef.current && isOpen && mode !== "view") {
      const length = quillRef.current.getLength();
      quillRef.current.setSelection(length - 1, 0);
      quillRef.current.focus();
    }
  }, [quillRef, isOpen, mode, text]);

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
    if (quillRef.current && isOpen) {
      mode !== "view" && quillRef.current.setText(EMPTY);
      onCancel && onCancel();
    }
  };

  const getEditorValue = () => {
    try {
      if (quillRef.current) {
        const editorContent = quillRef.current.getContents();
        const htmlContent = quillRef.current.getSemanticHTML();
        const mentioned = editorContent.ops
          .map((op) => {
            if (op.insert && typeof op.insert === "object") {
              const { mention } = op.insert;
              if (mention) {
                return mention?.["id"];
              }
            }
          })
          .filter(removeEmpty);
        return {
          content: JSON.stringify(editorContent),
          mentioned,
          htmlContent
        };
      }
    } catch (e) {
      console.error("error on getting editor value", e);
    }
    return undefined;
  };

  const handleSaveClick = () => {
    const editorValue = getEditorValue();
    if (!editorValue || isDisabled) {
      return;
    }
    const safeEditorValue = sanitizeEditorData(editorValue);
    onSave && onSave(safeEditorValue);
    toggleCollapse();
  };

  quillRef.current?.on("text-change", (_) => {
    const isEmpty = (quillRef.current?.getLength() || 0) <= 1;
    if (isEmpty !== isDisabled) {
      setIsDisabled(isEmpty);
    }
  });

  return (
    <Box>
      <Input
        hidden={isOpen}
        onClick={toggleCollapse}
        isReadOnly={true}
        placeholder={closedPlaceholder || "Add a comment..."}
        fontSize={"14px"}
        borderColor={"gray.400"}
        color={"gray.600"}
        _hover={{ borderColor: "gray.400" }}
      />

      <Box hidden={!isOpen}>
        <Box
          ref={editorRef}
          style={{
            height: "auto"
          }}
        />
        {mode !== "view" && (
          <Flex gap={2} mt={2}>
            <Button
              {...elphiTheme.components.light.button.primary}
              onClick={handleSaveClick}
              size={"sm"}
              isDisabled={isDisabled}
            >
              Save
            </Button>
            <Button
              {...elphiTheme.components.light.button.secondary}
              onClick={toggleCollapse}
              size={"sm"}
            >
              Cancel
            </Button>
          </Flex>
        )}
      </Box>
    </Box>
  );
};

const tryParse = (text: string) => {
  try {
    return JSON.parse(text);
  } catch (e) {
    console.error(`error on parsing RichTextEditor text: ${text}`, e);
  }
};
