import { Box, Progress } from "@chakra-ui/react";
import { FirebaseCollections } from "@elphi/types";
import { useMemo } from "react";
import { ExpandAllButton } from "../../common-components/expandAllButton/ExpandAllButton";
import useDealHooks from "../../hooks/deal.hooks";
import { formatSnapshot, useSnapshotHooks } from "../../hooks/snapshot.hooks";
import { DealSliceState } from "../../redux/v2/deal";
import { NavigationPath } from "../../shared/types/navigation.types";
import FormBuilder, { FormBuilderProps } from "../form-builder/FormBuilder";
export type TabFormProps = FormBuilderProps & {
  isLoading: boolean;
  dealId: string;
};

export const TabFormContainer = (
  props: TabFormProps & { snapshotId?: string } & {
    navigationPath?: NavigationPath;
    enableExpandAll?: boolean;
  }
) => {
  const { snapshotState } = useSnapshotHooks();
  const { dealState } = useDealHooks();
  const dealSlice = useMemo(
    () =>
      props.snapshotId
        ? formatSnapshot<DealSliceState>({
            snapshot: snapshotState.entities[props.snapshotId]?.snapshot,
            collection: FirebaseCollections.Deal
          })
        : dealState,
    [props.snapshotId]
  );
  if (!dealSlice) {
    return <></>;
  }
  return <TabFormComponent {...props} dealSlice={dealSlice} />;
};
const TabFormComponent = (
  props: TabFormProps & { dealSlice: DealSliceState } & {
    navigationPath?: NavigationPath;
    enableExpandAll?: boolean;
  }
) => {
  return (
    <Box h="100%" w="100%">
      {props.isLoading && (
        <Box w="100%">
          <Progress size="xs" isIndeterminate />
        </Box>
      )}
      {props.dealId && props.dealSlice.entities[props.dealId] && (
        <Box h="100%" w="100%" overflow="scroll">
          <Box>
            {!!props.enableExpandAll && !!props.navigationPath && (
              <ExpandAllButton navigationPath={props.navigationPath} />
            )}
          </Box>
          <FormBuilder
            customKey="tabFormSections"
            onChange={props.onChange}
            sections={props.sections}
            navigationPath={props.navigationPath}
          />
        </Box>
      )}
    </Box>
  );
};
export const TabForm = (
  props: TabFormProps & { dealSlice: DealSliceState }
) => {
  return (
    <Box h="100%" w="100%">
      {props.isLoading && (
        <Box w="100%">
          <Progress size="xs" isIndeterminate />
        </Box>
      )}
      {props.dealId && props.dealSlice.entities[props.dealId] && (
        <Box h="100%" w="100%" overflow="scroll">
          <FormBuilder
            customKey="tabForm"
            onChange={props.onChange}
            sections={props.sections}
          />
        </Box>
      )}
    </Box>
  );
};
