import { Box, BoxProps, Button } from "@chakra-ui/react";
import { FieldStatus, FieldType } from "@elphi/types";
import { get } from "lodash";
import {
  fieldStatusToColor,
  fieldStatusToLetter,
  nextFieldStatus
} from "../task/field-selection/d2v.utils";
import { OnChangeInput } from "./FormBuilder";

export type AttachedComponentProps<T extends object> = {
  state: T;
  prefix: string[];
  fieldPath: string[];
  isReadOnly?: boolean;
  isDisabled?: boolean;
  onChange?: (v: OnChangeInput) => void;
  containerStyle?: BoxProps;
  status?: FieldStatus;
};
export const AttachedFieldStatusComponet = <T extends object>(
  props: AttachedComponentProps<T>
) => {
  const status =
    props.status ||
    get(props.state, [
      ...props.prefix,
      "fieldMeta",
      ...props.fieldPath,
      "status"
    ]);
  return (
    <Box pt="8px" pl="4px" pr="4px" {...props.containerStyle}>
      <Button
        isDisabled={props.isDisabled}
        size="xs"
        float={"right"}
        boxShadow={"md"}
        cursor={!!props.onChange ? "pointer" : "default"}
        _hover={{}}
        color={"white"}
        borderRadius={"50%"}
        bgColor={fieldStatusToColor[status || FieldStatus.None]}
        onClick={() => {
          const fieldStatus = nextFieldStatus(status);

          !!props.onChange &&
            props.onChange({
              fieldKey: [
                ...props.prefix,
                "fieldMeta",
                ...props.fieldPath,
                "status"
              ],
              fieldType: FieldType.String,
              value: fieldStatus
            });
        }}
      >
        {fieldStatusToLetter[status || FieldStatus.None]}
      </Button>
    </Box>
  );
};
